import React, {
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DialogInterface } from '../../utils/interfaces';
import Button from '../Button';
import MessageDialog from './MessageDialog';
import ActionDialog from './ActionDialog';
import UploadFileDialog from './UploadFileDialog';
import BetSlipDialog from './BetSlipDialog';
import CaseDialog from './CaseDialog';
import { useSessionService } from '../../hook/useSessionService';
import { useSocket } from '../../hook/useSocket';
import { useCacheService } from '../../hook/useCacheService';
import { useTranslation } from 'react-i18next';
import { removeDialog, addDialog } from '../../redux/state/dialog';
import { editStatus } from '../../redux/state/status';
import ButtonDialog from './ButtonDialog';
import Swal from 'sweetalert2';
import { getBrowser, getPlatform } from '../../utils/salesforceVisitorDetails';

const Dialogs = () => {
    const socket = useSocket();
    const cacheService = useCacheService();
    const botIdRequireLanguageSelection = [49, 75, '49', '75'];

    const { t } = useTranslation();

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const botId = useSelector(
        (state: { session: { botId: string } }) => state.session.botId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const nps = useSelector(
        (state: { session: { nps: string } }) => state.session.nps
    );

    const matchStartDate = useSelector(
        (state: { session: { matchStartDate: string } }) =>
            state.session.matchStartDate
    );

    const betslipId = useSelector(
        (state: { session: { betslipId: string } }) => state.session.betslipId
    );

    const queue = useSelector(
        (state: { session: { queue: string } }) => state.session.queue
    );

    const dialogs = useSelector(
        (state: { dialog: { dialogs: DialogInterface[] } }) =>
            state.dialog.dialogs
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const enableOfflineForm = useSelector(
        (state: { settings: { enableOfflineForm: boolean } }) =>
            state.settings.enableOfflineForm
    );

    const useExternalLinkForOfflineForm = useSelector(
        (state: { settings: { useExternalLinkForOfflineForm: boolean } }) =>
            state.settings.useExternalLinkForOfflineForm
    );

    const externalLinkForOfflineForm = useSelector(
        (state: { settings: { externalLinkForOfflineForm: string } }) =>
            state.settings.externalLinkForOfflineForm
    );

    const forceClose = useSelector(
        (state: { systemMessages: { force_close: { message: string } } }) =>
            state.systemMessages.force_close.message
    );

    const noRespond = useSelector(
        (state: { systemMessages: { no_respond: { message: string } } }) =>
            state.systemMessages.no_respond.message
    );

    const leaveChat = useSelector(
        (state: { systemMessages: { leave_chat: { message: string } } }) =>
            state.systemMessages.leave_chat.message
    );

    const endChat = useSelector(
        (state: { systemMessages: { end_chat: { message: string } } }) =>
            state.systemMessages.end_chat.message
    );

    const networkProblem = useSelector(
        (state: { systemMessages: { network_problem: { message: string } } }) =>
            state.systemMessages.network_problem.message
    );

    const timeoutWarningMessage = useSelector(
        (state: {
            systemMessages: { timeout_warning_message: { message: string } };
        }) => state.systemMessages.timeout_warning_message.message
    );

    const quickReplyMessage = useSelector(
        (state: {
            systemMessages: { quickreplymessage: { message: string } };
        }) => state.systemMessages.quickreplymessage.message
    );

    const reconnectChat = useSelector(
        (state: { systemMessages: { reconnect_chat: { message: string } } }) =>
            state.systemMessages.reconnect_chat.message
    );

    const rejoinChat = useSelector(
        (state: { systemMessages: { rejoin_chat: { message: string } } }) =>
            state.systemMessages.rejoin_chat.message
    );

    const liveAgentInitFailMessage = useSelector(
        (state: {
            systemMessages: { live_agent_init_fail: { message: string } };
        }) => state.systemMessages.live_agent_init_fail.message
    );

    const leaveMessage = useSelector(
        (state: { systemMessages: { leave_message: { message: string } } }) =>
            state.systemMessages.leave_message.message
    );

    const uploadFile = useSelector(
        (state: {
            systemMessages: { upload_file_button: { message: string } };
        }) => state.systemMessages.upload_file_button.message
    );

    const chatMode = useSelector(
        (state: { session: { chatMode: string } }) => state.session.chatMode
    );

    const vip = useSelector(
        (state: { session: { isVip: any } }) => state.session.isVip
    );

    const refreshWhenBot = useSelector(
        (state: { session: { refreshWhenBot: boolean } }) =>
            state.session.refreshWhenBot
    );

    const laAffinityToken = useSelector(
        (state: { session: { laAffinityToken: string } }) =>
            state.session.laAffinityToken
    );

    const laSessionKey = useSelector(
        (state: { session: { laSessionKey: string } }) =>
            state.session.laSessionKey
    );

    const referrerSite = useSelector(
        (state: { session: { referrerSite: string } }) => state.session.referrerSite
    );

    const usernameFromUrl = useSelector(
        (state: { session: { usernameFromUrl: boolean } }) => state.session.usernameFromUrl
    );

    const customValues = useSelector(
        (state: { settings: { customValues: string } }) =>
            state.settings.customValues
    );

    const enQueue = useSelector(
        (state: { session: { enQueue: boolean } }) => state.session.enQueue
    );
    
    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const apiPath = useSelector(
        (state: { session: { apiPath: string } }) => state.session.apiPath
    );

    const caseNumber = useSelector(
        (state: { session: { caseNumber: string } }) => state.session.caseNumber
    );

    const liveChatValues = useSelector(
        (state: { settings: { botMarioConfig: { defaultValues: string } } }) =>
            state.settings.botMarioConfig.defaultValues
    );

    const dispatch = useDispatch();

    const closeDialog = (type: string) => {
        dispatch(removeDialog(type));
    };

    const handleTimeoutWarningClick = () => {
        cacheService.refreshCache();
        closeDialog('timeoutWarning');
    };

    const handleReload = () => {
        window.location.reload();
    };

    const handleConnectAgent = () => {
        closeDialog('connectAgent');
        console.log('connect agent', botId, botIdRequireLanguageSelection, botIdRequireLanguageSelection.includes(botId));

        console.log("IS VIP BUTTON", vip, typeof vip, vip[0]);
        let data = JSON.parse(customValues);
        if (vip[1] == "notChecked"){
            let data = JSON.parse(customValues);
            socket.sendCheckVipEvent({
                chatId: chatId,
                username: username,
                usernameFromUrl: true, //check
                recheckVip: true,
                checkVipDetails: data['check_vip_details'] ? data['check_vip_details'] : null
            })

        }
        if (botIdRequireLanguageSelection.includes(botId)) {
            Swal.fire({
                text: t('Preferred Language'),
                input: 'select',
                inputOptions: {
                    en: 'English',
                    hindi: 'हिंदी',
                },
                inputPlaceholder: t('Please select your language'),
                showCancelButton: false,
                confirmButtonColor: botButtonColor,
                allowOutsideClick: false,
                confirmButtonText: t('Confirm'),
                inputValidator: (value) => {
                    return new Promise((resolve: any) => {
                        if (value) {
                            resolve();
                        } else {
                            resolve(t('Please select your preferred language'));
                        }
                    });
                },
            }).then((result) => {
                if (result.value) {
                    dispatch(
                        editStatus({ name: 'queueStatus', value: 'loading' })
                    );
                    socket.sendConnectAgentEvent({
                        chatId: chatId,
                        isStartAgent: false,
                        isStartBot: true,
                        isVip: vip[0],
                        triggerTime: new Date().getTime().toString(),
                        language: 'chinese',
                        screen_resolution:
                            window.screen.width + 'x' + window.screen.height,
                        userAgent: navigator.userAgent,
                        nps: nps,
                        matchStartDateTime: matchStartDate,
                        betslipId: betslipId,
                        type: 'connect',
                        username: username,
                        queue: queue,
                        sfQueueId: result.value,
                        sfReferrer: referrerSite? referrerSite : document.referrer,
                        connect_flag: "CS Button Pressed",
                        cacheId: botId.toString() + sessionId,
                        cacheUrl : apiPath,
                        browser: getBrowser(),
                        sfPlatform: getPlatform(),
                        acqQueueFlag: JSON.parse(customValues).hasOwnProperty('acq_queue_flag') ? JSON.parse(customValues).acq_queue_flag : "",
                        enQueue: enQueue,
                        acqQueueDays: JSON.parse(customValues).hasOwnProperty('acq_queue_days') ? JSON.parse(customValues).acq_queue_days : "",
                        caseNumber: caseNumber,
                    });
                }
            });
        } else {
            dispatch(editStatus({ name: 'queueStatus', value: 'loading' }));
            socket.sendConnectAgentEvent({
                chatId: chatId,
                isStartAgent: false,
                isStartBot: true,
                isVip: vip[0],
                triggerTime: new Date().getTime().toString(),
                language: 'chinese',
                screen_resolution:
                    window.screen.width + 'x' + window.screen.height,
                userAgent: navigator.userAgent,
                nps: nps,
                matchStartDateTime: matchStartDate,
                betslipId: betslipId,
                type: 'connect',
                username: username,
                queue: queue,
                sfReferrer: referrerSite? referrerSite : document.referrer,
                connect_flag: "CS Button Pressed",
                cacheId: botId.toString() + sessionId,
                cacheUrl : apiPath,
                browser: getBrowser(),
                sfPlatform: getPlatform(),
                acqQueueFlag: JSON.parse(customValues).hasOwnProperty('acq_queue_flag') ? JSON.parse(customValues).acq_queue_flag : "",
                enQueue: enQueue,
                acqQueueDays: JSON.parse(customValues).hasOwnProperty('acq_queue_days') ? JSON.parse(customValues).acq_queue_days : "",
                caseNumber: caseNumber,
            });
        }

        // console.log("refresh when bot", refreshWhenBot);

        // if (refreshWhenBot && laAffinityToken && laSessionKey) {
        //     console.log("refresh when bot triggered");
        //     socket.sendChatEndEvent({
        //         chatId: chatId,
        //         affinityToken: laAffinityToken,
        //         key: laSessionKey,
        //     });
        // }
    };

    const handleOfflineForm = () => {
        dispatch(
            editStatus({
                name: 'isIframeModal',
                value:
                    true && enableOfflineForm && useExternalLinkForOfflineForm,
            })
        );
        dispatch(
            editStatus({
                name: 'iframeUrl',
                value: externalLinkForOfflineForm,
            })
        );
    };

    return (
        <div className="w-90pc medium:w-60pc absolute bottom-[12px] left-5pc medium:left-20pc text-sm z-10">
            {dialogs.map((dialog: DialogInterface, index) => {
                if (dialog.task == 'timeoutWarning') {
                    return (
                        <MessageDialog
                            color="grey"
                            message={timeoutWarningMessage}
                            key={index}
                            fn={handleTimeoutWarningClick}
                        />
                    );
                } else if (dialog.task == 'ended') {
                    return (
                        <ActionDialog
                            color="red"
                            message={endChat}
                            buttonMessage={rejoinChat}
                            key={index}
                            task={dialog.task}
                            fn={handleReload}
                        />
                    );
                } else if (dialog.task == 'leaved') {
                    return (
                        <ActionDialog
                            color="red"
                            message={leaveChat}
                            buttonMessage={rejoinChat}
                            key={index}
                            task={dialog.task}
                            fn={handleReload}
                        />
                    );
                } else if (dialog.task == 'disconnected') {
                    return (
                        <ActionDialog
                            color="red"
                            message={networkProblem}
                            buttonMessage={reconnectChat}
                            key={index}
                            task={dialog.task}
                            fn={handleReload}
                        />
                    );
                } else if (dialog.task == 'timeout') {
                    return (
                        <ActionDialog
                            color="red"
                            message={noRespond}
                            buttonMessage={reconnectChat}
                            key={index}
                            task={dialog.task}
                            fn={handleReload}
                        />
                    );
                } else if (
                    chatMode === 'bot' &&
                    dialog.task == 'connectAgent'
                ) {
                    // return (
                    //     <ActionDialog
                    //         color="blue"
                    //         // customColor={botButtonColor}
                    //         message={quickReplyMessage}
                    //         buttonMessage={t('connect')}
                    //         key={index}
                    //         task={dialog.task}
                    //         fn={handleConnectAgent}
                    //     />
                    // );
                    const defaultValues: { Default?: string[] } = JSON.parse(liveChatValues || '{}');

                    const defaultKeyExists =
                        defaultValues.Default &&
                        Array.isArray(defaultValues.Default) &&
                        defaultValues.Default.some((value: string) => value && value !== "null");
                    
                    if (defaultKeyExists) {
                        return (
                            <ButtonDialog
                                color="blue"
                                customColor={botButtonColor}
                                buttonMessage={quickReplyMessage}
                                key={index}
                                task={dialog.task}
                                fn={handleConnectAgent}
                            />
                        );
                    }
                } else if (dialog.task == 'offlineForm') {
                    return (
                        <ActionDialog
                            customColor={botButtonColor}
                            color="grey"
                            message={liveAgentInitFailMessage}
                            buttonMessage={leaveMessage}
                            key={index}
                            task={dialog.task}
                            fn={handleOfflineForm}
                        />
                    );
                } else if (dialog.task == 'forceClose') {
                    return (
                        <ActionDialog
                            color="grey"
                            message={forceClose}
                            buttonMessage={rejoinChat}
                            key={index}
                            task={dialog.task}
                            fn={handleReload}
                        />
                    );
                } else if (dialog.task == 'uploadFile') {
                    return (
                        <UploadFileDialog
                            message={uploadFile}
                            buttonMessage={t('upload')}
                            key={index}
                            task={dialog.task}
                            data={dialog.data}
                        />
                    );
                } else if (dialog.task == 'betSlip') {
                    return (
                        <BetSlipDialog
                            message={betslipId}
                            buttonMessage="Send"
                            key={index}
                            task={dialog.task}
                            data={dialog.data}
                        />
                    )
                } else if (dialog.task == 'caseDetails') {
                    return (
                        <CaseDialog
                            message="Test"
                            buttonMessage="Send"
                            key={index}
                            task={dialog.task}
                            data={dialog.data}
                        />
                    )
                }
            })}
        </div>
    );
};

Dialogs.propTypes = {};

export default Dialogs;
