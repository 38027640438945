import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SocketContextProvider from '../context/SocketContext';
import Loading from './Loading';
import Content from './Content';
import Container from './Container';
import ModalBackground from './ModalBackground';
import { useSelector, useDispatch } from 'react-redux';
import { dataLoader } from '../utils/dataLoader';
import Header from './Header';
import ImageModal from './ImageModal';
import PrechatModal from './Modal/PrechatModal';
import { useSearchParams } from 'react-router-dom';
import { useCacheService } from '../hook/useCacheService';
import { useSessionService } from '../hook/useSessionService';
import { useDataService } from '../hook/useDataService';
import { editSession, editSessions } from '../redux/state/session';
import { render } from '@testing-library/react';
import { addChat, editChat } from '../redux/state/chat';
import { ChatInterface } from '../utils/interfaces';
import ChatRatingModal from './ChatRatingModal/ChatRating';
import { Helmet } from 'react-helmet';
import { editStatus } from '../redux/state/status';
import IframeModal from './Modal/IframeModal';
import { useTranslation } from 'react-i18next';
import LoginModal from './Modal/LoginModal';
import LoadingModal from './Modal/LoadingModal';
import SuccessLoginModal from './Modal/SuccessLoginModal';
import FailLoginModal from './Modal/FailLoginModal';
import FormModal from './Modal/FormModal';
import Background from './Background';
import BackgroundProcess from './BackgroundProcess';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isMobileSafari,
    isIOS,
} from 'react-device-detect';
import QueueModal from './Modal/QueueModal';
import LoginIframeModal from './Modal/LoginIframeModal';
import { te } from 'date-fns/locale';
import { getCookies, removeCookie, setCookies } from '../utils/cookies';
import { addDialog} from '../redux/state/dialog';

interface MainProps {
    acctId: number;
    botId: number;
    brandId: 'welton' | 'masuta' | 'vpo' | 'gb' | 'bi' | 'bibo';
    dbProfile: 'mario.staging.1' | 'mario.production';
    client: string;
    title: string;
    apiPaths: string[];
    pluginPaths: string[];
    socketPaths: string[];
}

declare global {
    interface Window {
        encryptValue: any;
        testFunction: any;
    }
}

const Main = (props: MainProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(
        (state: { status: { isLoading: boolean } }) => state.status.isLoading
    );
    const settings = useSelector((state: { settings: any }) => state.settings);
    const systemMessages = useSelector(
        (state: { systemMessages: any }) => state.systemMessages
    );
    const [height, setHeight] = useState(window.innerHeight);
    const [debug, setDebug] = useState('');
    const session = useSelector((state: { session: any }) => state.session);
    // console.log('session at main', session);
    const [sessionId, setSessionId] = useState('');
    const [isPath, setIsPath] = useState(false);
    const [shuffledPath, setShufflePath] = useState(props.socketPaths);
    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const { getCache, popCache, setCacheByField, loadCache, setCache } =
        useCacheService();
    const sessionService = useSessionService();
    const dataService = useDataService();

    // const shuffle = (paths: string[]) => {
    //     return paths.sort(() => Math.random() - 0.5);
    // };

    const shuffle = (input:any) => {
        if (typeof input === 'string') {
            // If the input is a string, split it into array.
            input = input.split('');
        }
    
        // Generate a random index based on the array's length.
        const randomIndex = Math.floor(Math.random() * input.length);
    
        // Get the value in random index.
        const randomElement = [input[randomIndex]];
    
        return randomElement;
    };

    const initProcess = async () => {
        console.log('running init process', props);
        const tabId = new Date().getTime().toString();
        console.log("HERE TAB ID", tabId);
        const token = searchParams.get('token');

        const username = searchParams.get('CUSTOM!Username')
            ? searchParams.get('CUSTOM!Username')
            : searchParams.get('CUSTOM!1.Username')
            ? searchParams.get('CUSTOM!1.Username')
            : '';
        const betslipId = searchParams.get('CUSTOM!BetslipID')
            ? searchParams.get('CUSTOM!BetslipID')
            : '';
        const matchStartDate = searchParams.get('CUSTOM!MatchStartDate')
            ? searchParams.get('CUSTOM!MatchStartDate')
            : '';
        const vendor = searchParams.get('CUSTOM!Vendor')
            ? searchParams.get('CUSTOM!Vendor')
            : '';
        const status = searchParams.get('CUSTOM!Status')
            ? searchParams.get('CUSTOM!Status')
            : '';
        const memberDevice = searchParams.get('CUSTOM!MemberDevice')
            ? searchParams.get('CUSTOM!MemberDevice')
            : '';
        const nps = searchParams.get('CUSTOM!NPS')
            ? searchParams.get('CUSTOM!NPS')
            : '';
        const percentage = searchParams.get('CUSTOM!Percentage')
            ? searchParams.get('CUSTOM!Percentage')
            : '';
        const queue = searchParams.get('CUSTOM!Queue')
            ? searchParams.get('CUSTOM!Queue')
            : '';
        const referrerSite = searchParams.get('CUSTOM!ReferringSite')
            ? searchParams.get('CUSTOM!ReferringSite')
            : '';
        
        const caseNumber = searchParams.get('CUSTOM!CaseNumber')
            ? searchParams.get('CUSTOM!CaseNumber')?.slice(0,9)
            : '';

        const caseCategory = searchParams.get('CUSTOM!CaseCategory')
            ? searchParams.get('CUSTOM!CaseCategory')?.slice(0,31)
            : '';
        const handShakeToken = searchParams.get('HandshakeToken')
            ? searchParams.get('HandshakeToken')
            : '';
            
        const cachedToken = await sessionService.getCacheSession(
            props.acctId,
            props.botId
        );

        // let token = cachedToken ? cachedToken : inittoken;

        const enQueue = (searchParams.get('CUSTOM!ENQUEUE'))?.toLowerCase() === 'yes';
        
        dispatch(
            editSessions({
                botId: props.botId,
                acctId: props.acctId,
                brandId: props.brandId,
                dbProfile: props.dbProfile,
                apiPath: props.apiPaths[0],
                username,
                betslipId,
                matchStartDate,
                vendor,
                status,
                nps,
                percentage,
                queue,
                referrerSite,
                caseNumber,
                caseCategory,
                enQueue,
                handShakeToken
            })
        );

        await dataService.loadData(props.apiPaths[0], props.client);

        setTimeout(async () => {
            if (token || cachedToken) {
                dispatch(
                    editSession({
                        name: 'sessionId',
                        value: cachedToken ? cachedToken : token,
                    })
                );

                if (!cachedToken) {
                    const cookiesKey =
                    props.acctId.toString() +
                    ('00' + props.botId).slice(-3) +
                    '_token';
                    setCookies(cookiesKey, token);
                }

                if (!token && cachedToken)
                    sessionService.appendToken(cachedToken);
                
                if (token != cachedToken && cachedToken)
                    sessionService.replaceToken(cachedToken)

                // dataService.editSessionId(token ? token : cachedToken); //check
                const cache = await getCache(cachedToken ? cachedToken : token);

                const cacheChats = await popCache(
                    cachedToken ? cachedToken + '_chats' : token + '_chats'
                );

                if (cacheChats) {
                    cacheChats.map((chat: string, index: number) => {
                        dispatch(addChat(JSON.parse(chat)));
                    });
                }

                if (cacheChats && cacheChats.length > 0) {
                    if (cache && cache.chatMode == 'bot') {
                        dispatch(
                            editSession({
                                name: 'refreshWhenBot',
                                value: true,
                            })
                        );
                        setCacheByField(cachedToken, 'refreshWhenBot', true);
                        console.log('should append welcome message');
                    }
                }

                console.log(cache ? 'is cache' : 'no cache', cache, cacheChats);
                if (cache) {
                    dispatch(
                        editStatus({
                            name: 'isCached',
                            value: true,
                        })
                    );
                    dispatch(
                        editSession({
                            name: 'isCached',
                            value: true,
                        })
                    );
                    dispatch(
                        editSession({
                            name: 'chatRatingDone',
                            value: cache.chatRatingDone,
                        })
                    );
                    dispatch(
                        editSession({
                            name: 'postChatUrl',
                            value: cache.postChatUrl,
                        })
                    );
                    dispatch(
                        editSession({
                            name: 'sentBetSlip',
                            value: cache.sentBetSlip
                        })
                    );
                    dispatch(
                        editSession({
                            name: 'betSlipVals',
                            value: cache.betSlipVals
                        })
                    );

                    dispatch(
                        editSession({
                            name: 'sentCaseDetails',
                            value: cache.sentCaseDetails
                        })
                    );

                    if (cache.hasOwnProperty('chatId')) {
                        dispatch(
                            editSession({
                                name: 'chatId',
                                value: cache.chatId,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('isVip')) {
                        let tempvip = cache.isVip;
                        if (typeof tempvip === "string") {
                            tempvip = tempvip.split(",");
                            tempvip[0] = tempvip[0] === "true" ? true: false ;
                        }
                        console.log("Transformed cache vip values", tempvip, typeof tempvip);

                        dispatch(
                            editSession({
                                name: 'isVip',
                                value: tempvip,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('chatMode')) {
                        dispatch(
                            editSession({
                                name: 'chatMode',
                                value: cache.chatMode,
                            })
                        );
                    }
                    if (!username && cache.hasOwnProperty('username')) {
                        if (cache.username.trim().length > 0) {
                            dispatch(
                                editSession({
                                    name: 'username',
                                    value: cache.username,
                                })
                            );
                        }
                    }
                    if (cache.hasOwnProperty('laAffinityToken')) {
                        dispatch(
                            editSession({
                                name: 'laAffinityToken',
                                value: cache.laAffinityToken,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('laSessionKey')) {
                        dispatch(
                            editSession({
                                name: 'laSessionKey',
                                value: cache.laSessionKey,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('laSessionId')) {
                        dispatch(
                            editSession({
                                name: 'laSessionId',
                                value: cache.laSessionId,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('laId')) {
                        dispatch(
                            editSession({
                                name: 'laId',
                                value: cache.laId,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('laName')) {
                        dispatch(
                            editSession({
                                name: 'laName',
                                value: cache.laName,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('currentStatusPlugin')) {
                        dispatch(
                            editSession({
                                name: 'currentStatusPlugin',
                                value: cache.currentStatusPlugin,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('currentStatusClient')) {
                        dispatch(
                            editSession({
                                name: 'currentStatusClient',
                                value: cache.currentStatusClient,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('queuePosition')) {
                        dispatch(
                            editSession({
                                name: 'queuePosition',
                                value: cache.queuePosition,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('isStartBot')) {
                        dispatch(
                            editSession({
                                name: 'isStartBot',
                                value:
                                    cache.isStartBot === 'true' ? true : false,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('isStartAgent')) {
                        dispatch(
                            editSession({
                                name: 'isStartAgent',
                                value:
                                    cache.isStartAgent === 'true'
                                        ? true
                                        : false,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('isBotToAgent')) {
                        dispatch(
                            editSession({
                                name: 'isBotToAgent',
                                value:
                                    cache.isBotToAgent === 'true'
                                        ? true
                                        : false,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('respondedToBot')) {
                        dispatch(
                            editSession({
                                name: 'respondedToBot',
                                value:
                                    cache.respondedToBot === 'true'
                                        ? true
                                        : false,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('respondedToAgent')) {
                        dispatch(
                            editSession({
                                name: 'respondedToAgent',
                                value:
                                    cache.respondedToAgent === 'true'
                                        ? true
                                        : false,
                            })
                        );
                    }

                    if (cache.hasOwnProperty('queueStatus')) {
                        dispatch(
                            editSession({
                                name: 'queueStatus',
                                value: cache.queueStatus,
                            })
                        );
                    }

                    if (cache.hasOwnProperty('refreshWhenQueueing')) {
                        dispatch(
                            editSession({
                                name: 'refreshWhenQueueing',
                                value: cache.refreshWhenQueueing,
                            })
                        );
                    }

                    if (cache.hasOwnProperty('historyTranscriptSent')) {
                        dispatch(
                            editSession({
                                name: 'historyTranscriptSent',
                                value: cache.historyTranscriptSent,
                            })
                        );
                    }

                    if (cache.hasOwnProperty('waitingAnswerReply')) {
                        const waitingAnswerReply = cache.waitingAnswerReply === "true";
                        dispatch(
                            editSession({
                                name: 'waitingAnswerReply',
                                value: waitingAnswerReply,
                            })
                        );
                    }

                    if (cache.hasOwnProperty('disableChatRatingSubmitted')) {
                        dispatch(
                            editSession({
                                name: 'disableChatRatingSubmitted',
                                value: cache.disableChatRatingSubmitted,
                            })
                        );
                    }
                    if (cache.hasOwnProperty('retryCacheAttemps')) {
                        dispatch(
                            editSession({
                                name: 'retryCacheAttemps',
                                value: Number(cache.retryCacheAttemps),
                            })
                        );
                    }

                    if (cache.hasOwnProperty('refreshCounter')) {
                        let tempCounter = parseInt(cache.refreshCounter);
                        tempCounter += 1;
                        dispatch(
                            editSession({
                                name: 'refreshCounter',
                                value: tempCounter,
                            })
                        );
                        setCacheByField(token, 'refreshCounter', tempCounter);

                        console.log("Refresh Counter val", cache.refreshCounter, typeof cache.refreshCounter);

                        
                    } else {
                        dispatch(
                            editSession({
                                name: 'refreshCounter',
                                value: "1",
                            })
                        );
                        setCacheByField(token, 'refreshCounter', "1");
                    }

                    if (cache.hasOwnProperty('socketPaths')) {
                        //setShufflePath(JSON.parse(cache.socketPaths));
                        let tempSocketPath = cache.socketPaths;
                        tempSocketPath = tempSocketPath.replace("[", "");
                        tempSocketPath = tempSocketPath.replace("]", "");
                        setShufflePath([tempSocketPath]);
                        setIsPath(true);
                    } else {
                        const socketPath = shuffle(props.socketPaths);
                        setShufflePath(socketPath);
                        setIsPath(true);
                        // for (let step = 0; step < 100; step++) {
                        //     setCacheByField(
                        //         token + step.toString(),
                        //         'socketPaths',
                        //         JSON.stringify(shuffledPath)
                        //     );
                        // }
                        dispatch(editSession({ name: 'socketPaths', value: socketPath }));
                        setCacheByField(
                            token,
                            'socketPaths',
                            JSON.stringify(socketPath)
                        );
                    }
                    loadCache(cache);
                } else {
                    // TODO check cache value from redis
                    const socketPath = shuffle(props.socketPaths);
                    setShufflePath(socketPath);
                    setIsPath(true);
                    dispatch(editSession({ name: 'socketPaths', value: socketPath }));
                }
            } else {
                const newToken = await sessionService.createSession(
                    props.acctId,
                    props.botId
                );
                const socketPath = shuffle(props.socketPaths);
                setShufflePath(socketPath);
                setIsPath(true);
                dispatch(editSession({ name: 'sessionId', value: newToken }));
                dispatch(editSession({ name: 'socketPaths', value: socketPath }));

            }
        }, 0);

        console.log('token', token, cachedToken, tabId);
        dispatch(editSession({ name: 'tabId', value: tabId }));
        // setCacheByField(token, 'tabId', tabId);

    };

    useEffect(() => {
        initProcess();
    }, []);

    useEffect(() => {
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        const handleResize = () => {
            // setTimeout(() => {
            //     if (isMobile && (isMobileSafari || isIOS)) {
            //         setHeight(window.innerHeight);
            //     } else {
            //         setHeight(window.visualViewport.height);
            //     }
            // }, 500);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {

        window.addEventListener('offline', (e) => {
            dispatch(addDialog({ task: 'disconnected', type: 'action' }));
        })

    });

    useEffect(() => {
        console.log('Set cache at main', session);
        // for (let step = 0; step < 100; step++) {
        //     setCache(session.sessionId +step.toString() , session);
        // }

        if (session.sessionId) {
            setCache(session.sessionId, session);
            setCacheByField(session.sessionId, 'tabId', session.tabId);
        }
        if (session.laAffinityToken) {
            setCacheByField(session.sessionId, "status", "active")
        }
    }, [session.socketPaths, session.laAffinityToken, session.tabId]);

    const isLoginModal = useSelector(
        (state: { status: { isLoginModal: Boolean } }) =>
            state.status.isLoginModal
    );
    const isLoadingModal = useSelector(
        (state: { status: { isLoadingModal: Boolean } }) =>
            state.status.isLoadingModal
    );
    const isSuccessLoginModal = useSelector(
        (state: { status: { isSuccessLoginModal: Boolean } }) =>
            state.status.isSuccessLoginModal
    );
    const isFailLoginModal = useSelector(
        (state: { status: { isFailLoginModal: Boolean } }) =>
            state.status.isFailLoginModal
    );
    const isFormModal = useSelector(
        (state: { status: { isFormModal: Boolean } }) =>
            state.status.isFormModal
    );
    const botBgImage = useSelector(
        (state: { settings: { botBgImage: string } }) =>
            state.settings.botBgImage
    );

    const botBgImageOpacity = useSelector(
        (state: { settings: { botBgImageOpacity: number } }) =>
            state.settings.botBgImageOpacity
    );

    const botBgImageFormat = useSelector(
        (state: { settings: { botBgImageFormat: number } }) =>
            state.settings.botBgImageFormat
    );
    const isLoginIframeModal = useSelector(
        (state: { status: { isLoginIframeModal: Boolean } }) =>
            state.status.isLoginIframeModal
    );

    const resizeHandler = () => {
        // setDebug(
        //     `visual viewport: ${window.visualViewport.width} x ${window.visualViewport.height}`
        // );
        setTimeout(() => {
            // setHeight(window.visualViewport.height);
            if (isMobile) {
                setHeight(window.innerHeight);
            } else {
                setHeight(window.visualViewport? window.visualViewport.height : window.innerHeight);
            }
        }, 0);
        // setHeight(window.visualViewport.height);
        // if (isMobile) {
        //     setHeight(window.visualViewport.height);
        // }
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         window.scrollTo(0, 0);
    //         document.body.scrollTop = 0;
    //         // console.log('scroll to bottom', document.body.scrollHeight);
    //         // if (document.body.scrollHeight !== 0) {
    //         //     window.scrollTo(0, document.body.scrollHeight);
    //         // }
    //     }, 0);
    // }, [height]);

    useEffect(() => {
        // setDebug(
        //     `visual viewport: ${window.visualViewport.width} x ${window.visualViewport.height}`
        // );
        window.visualViewport?.addEventListener('resize', resizeHandler);

        return () => {
            window.visualViewport?.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <div style={{ height: height }} className="fixed overflow-hidden">
            {isPath && (
                <SocketContextProvider
                    socketPaths={shuffledPath}
                    session={session}
                    systemMessages={systemMessages}
                    settings={settings}
                    setDebug={setDebug}
                    debug={debug}
                >
                    <div className="relative h-full w-screen flex justify-center items-center overflow-hidden">
                        <BackgroundProcess />
                        <Background
                            url={botBgImage}
                            opacity={
                                botBgImageOpacity
                                    ? botBgImageOpacity / 100
                                    : 0.2
                            }
                            format={botBgImageFormat}
                        />
                        <Helmet>
                            <title>{props.title}</title>
                        </Helmet>
                        <ModalBackground />
                        <Loading
                            isLoading={isLoading}
                            customizationType="wave"
                            customizationColor={`bg-slate-300`}
                        />
                        <ImageModal height={height} />
                        <IframeModal height={height} />
                        <Container debug={debug} height={height} />
                        <PrechatModal height={height} />
                        <ChatRatingModal height={height} />
                        {isLoginModal && <LoginModal height={height} />}
                        {isLoginIframeModal && (
                            <LoginIframeModal height={height} />
                        )}
                        {isLoadingModal && <LoadingModal />}
                        {isSuccessLoginModal && <SuccessLoginModal />}
                        {isFailLoginModal && <FailLoginModal />}
                        {isFormModal && <FormModal height={height} />}
                    </div>
                </SocketContextProvider>
            )}
        </div>
    );
};

export default Main;
