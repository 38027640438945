import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Interweave from 'interweave';
// import Email from '../component/FormComponents/Email';
// import Password from '../component/FormComponents/Password';
// import Text from '../component/FormComponents/Text';
// import DropDownList from '../component/FormComponents/DropDownList';
import FormFields from '../../component/FormComponents/FormFields';
// import FormMessage from '../component/FormButton';
import { getSuggestedQuery } from '@testing-library/react';
import { getPlugin } from 'immer/dist/internal';
import { useDispatch, useSelector } from 'react-redux';
import { editStatus } from '../../redux/state/status';
import { editSession } from '../../redux/state/session';
import { useSocket } from '../../hook/useSocket';
import format from 'date-fns/format';
import {
    ChatInterface,
    DialogInterface,
    JsFuntionInterface,
    ReduxFunctionInterface,
    SendMessageEventInterface,
} from '../../utils/interfaces';
import { addChat } from '../../redux/state/chat';
import { useCacheService } from '../../hook/useCacheService';
import { useTranslation } from 'react-i18next';
import { getOS } from '../../utils/navigator';
interface Props {
    height: number;
}
const FormModal = (props: Props) => {
    const { t } = useTranslation();

    var PostMessage: string = '';
    var InputClassName =
        'outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-3 mb-6';
    var InvalidInputClassName =
        'bg-gray-50 border outline-none border-red-300 text-gray-900 text-sm rounded-lg w-full p-3 mb-6';

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const isFormModal = useSelector(
        (state: { status: { isFormModal: Boolean } }) =>
            state.status.isFormModal
    );

    const content = useSelector(
        (state: { session: { formContent: any } }) => state.session.formContent
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const botLanguage = useSelector(
        (state: { settings: { botLanguage: string } }) =>
            state.settings.botLanguage
    );

    const processor = useSelector(
        (state: { settings: { processor: number } }) =>
            state.settings.processor
    );

    const botName = useSelector(
        (state: { settings: {botName: string}}) =>
            state.settings.botName
    );

    const apiPath = useSelector(
        (state: { session: { apiPath: string } }) =>
            state.session.apiPath
    );

    const botId = useSelector(
        (state: { session: { botId: string } }) => state.session.botId
    );

    const gloqoBrandCode = useSelector(
        (state: { settings: { gloqoBrandCode: string } }) => state.settings.gloqoBrandCode
    )

    const gloqoMarketCode = useSelector(
        (state: { settings: { gloqoMarketCode: string } }) => state.settings.gloqoMarketCode
    )

    const socket = useSocket();
    const dispatch = useDispatch();
    const cacheService = useCacheService();

    const closeFormModal = () => {
        dispatch(
            editStatus({
                name: 'isFormModal',
                value: false,
            })
        );
    };
    // { "chinese": "zh-cn" },
    // { "thai":  "th-th" },
    // { "vietnamese":  "vi-vn" },
    // { "vietnam":  "vi-vn" },
    // { "english":  "en" }
    const formSubmit = () => {
        console.log('formValues', FormValues);
        let cacheInfo = {
            sessionId: botId.toString() + sessionId,
            cacheUrl: apiPath,
            botName: botName
        }
        const data = {
            chatId: chatId,
            customer_lang_culture:
                botLanguage.toLowerCase() === 'chinese'
                    ? 'zh-cn'
                    : botLanguage.toLowerCase() === 'thai'
                    ? 'th-th'
                    : botLanguage.toLowerCase() === 'vietnamese'
                    ? 'vi-vn'
                    : botLanguage.toLowerCase() === 'vietnam'
                    ? 'vi-vn'
                    : botLanguage.toLowerCase() === 'english'
                    ? 'en'
                    : botLanguage.toLowerCase(),
            operating_system: getOS(navigator),
            screen_resolution: window.screen.width + '*' + window.screen.height,
            formValues: JSON.stringify(FormValues),
            type: 'formSubmit',
            username: username,
            processor: processor,
            cacheInfo: cacheInfo,
            brandInfo : {
                brand: gloqoBrandCode,
                market: gloqoMarketCode
            },
        };
        socket.sendFormSubmitEvent(data);
        closeFormModal();
        for (const item of FormValues) {
            for (const field of formFields) {
                if (item.name === field.name) {
                    if (field.masked) {
                        item.value = '********';
                    }
                    // console.log(field.displayName + ' : ' + item.value);
                    const displayName = field.displayName
                        ? field.displayName
                        : field.name;
                    PostMessage += displayName + ' : ' + item.value + '\n';
                }
            }
        }
        // console.log(PostMessage);
        const content = {
            owner: 'user',
            name: username,
            type: 'text',
            message: {
                content: PostMessage,
            },
            displayTime: format(new Date(), 'hh:mmaa'),
            createdBy: new Date().getTime(),
        } as ChatInterface;
        cacheService.pushCache(sessionId + '_chats', JSON.stringify(content));
        dispatch(addChat(content));
    };

    const formMessage = content.message;
    const formTitle = content.text;
    const submitButtonText = content.submitButtonText;
    const formFields = content.fields;
    console.log('formFields', formFields);

    let FormValues: any = [];

    const fields = (item: any) => {
        // console.log(item)
        const fieldName: string = item.name;
        const fieldNameValue: any = new Object();
        const fieldIfRequired: boolean = item.ifRequired;
        let prefill_value = item.prefill_value;
        fieldNameValue.name = fieldName;
        fieldNameValue.value = '';
        FormValues.push(fieldNameValue);
        const changeFieldValue = (event: any) => {
            // console.log(fieldName, event.target.value);
            for (const field of FormValues) {
                if (field.name === fieldName) {
                    field.value = event.target.value;
                    let inputFieldElement:any = document.getElementById(field.name);
                    if (event.target.value === '' && fieldIfRequired) {
                        if (inputFieldElement != undefined) {
                            inputFieldElement.className = InvalidInputClassName;
                        };
                    }
                    else {
                        if (inputFieldElement.className != InputClassName) {
                            inputFieldElement.className = InputClassName;
                        }
                    };
                };
            };
            // console.log('formValues:', FormValues);
        };
        const changePrefillFieldValue = (event:any) => {
            for (const field of FormValues) {
                if (field.name === fieldName) {
                    field.value = prefill_value + event.target.value;
                };
            };
        };
        switch (item.type) {
            case 'text': {
                if (item.masked) {
                    return (
                        <div>
                            {/* <label htmlFor={item.name} className="block mb-2 text-sm font-medium text-gray-900"></label> */}
                            <input
                                onChange={changeFieldValue}
                                type="password"
                                name={item.name}
                                id={item.name}
                                placeholder={
                                    item.displayName
                                        ? item.displayName
                                        : item.name
                                }
                                className={InputClassName}
                                required={fieldIfRequired ? fieldIfRequired : false}
                            ></input>
                        </div>
                    );
                } else {
                    if (item.prefill_value) {
                        return (
                            <div className='flex justify-center items-center'>
                                {/* <label htmlFor={item.name} className="block mb-2 text-sm font-medium text-gray-900"></label> */}
                                <input
                                    disabled
                                    type="text"
                                    name="prefill_value"
                                    id="prefill_value"
                                    placeholder={item.prefill_value}
                                    className='mr-2 ml-2 w-1/5 outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm text-center rounded-lg p-3 mb-6'
                                    required={fieldIfRequired ? fieldIfRequired : false}
                                    // autoComplete="off"
                                ></input>
                                <input
                                    onChange={changePrefillFieldValue}
                                    type="text"
                                    name={item.name}
                                    id={item.name}
                                    placeholder={
                                        item.displayName
                                            ? item.displayName
                                            : item.name
                                    }
                                    className='w-3/5 outline-none appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-3 mb-6'
                                    required={fieldIfRequired ? fieldIfRequired : false}
                                ></input>
                                </div>
                        )
                    }
                    else {
                        return (
                            <div>
                                {/* <label htmlFor={item.name} className="block mb-2 text-sm font-medium text-gray-900"></label> */}
                                <input
                                    onChange={changeFieldValue}
                                    type="text"
                                    name={item.name}
                                    id={item.name}
                                    placeholder={
                                        item.displayName
                                            ? item.displayName
                                            : item.name
                                    }
                                    className={InputClassName}
                                    required={fieldIfRequired ? fieldIfRequired : false}
                                ></input>
                            </div>
                        );
                    }
                }
            }
            case 'dropDownList': {
                const optionValue = item.options.map((option: any) => {
                    return <option value={option}>{option}</option>;
                });
                return (
                    // <div className="text-gray-900 text-lg block w-full p-3">
                    <div>
                        <select
                            className="w-full text-sm text-gray-900 rounded-lg p-3 border-gray-900 shadow-lg bg-slate-200 mb-6"
                            id={item.name}
                            name={item.name}
                            onClick={changeFieldValue}
                            required
                        >
                            <option value="" disabled selected>
                                {item.displayName
                                    ? item.displayName
                                    : item.name}
                            </option>
                            {optionValue}
                        </select>
                    </div>
                );
            }
        }
    };
    const inputFields = formFields.map(fields);

    return (
        <div
            style={{ height: props.height }}
            className="fixed w-screen bg-zinc-800/90 flex justify-center items-center z-20"
        >
            <div className="w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-y-auto max-h-full">
                <div className="relative rounded-lg shadow">
                    <div className="flex justify-end p-4">
                        <button
                            onClick={closeFormModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-700 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-toggle="authentication-modal"
                        >
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8">
                        <div>
                            {/* <h5 className="text-xl font-medium text-gray-900">{formMessage}</h5> */}
                            <h5 className="text-xl font-medium text-gray-900">
                                {formTitle}
                            </h5>
                        </div>
                        <div>
                        <form onSubmit={formSubmit}>
                        {inputFields}
                        <input
                            type="submit"
                            style={{
                                backgroundColor: botButtonColor
                                    ? botButtonColor.slice(0, 7)
                                    : '',
                            }}
                            className={classNames(
                                'flex justify-center items-center w-80pc px-4 py-2 rounded-lg mx-auto mb-6 text-white font-bold shadow-lg',
                                {
                                    'bg-sky-500': !botButtonColor,
                                }
                            )}
                            value={submitButtonText
                                ? submitButtonText
                                : t('submit')}
                            />
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FormModal.propTypes = {};

export default FormModal;